<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="order-details-start">
      <div class="new-container">
        <div class="row">
          <div class="col-md-12">
            <div class="chat">
              <div class="chat-history" id="allMessagesId">
                <!-- <ul class="m-b-0 msg-list" v-html="innerHtml">                      
              </ul> -->
                <ul class="m-b-0 msg-list">
                  <chat-content v-for="(message, i) in messages" :key="i" :msg="message"
                    v-on:imageOpen="openPreviewImage"></chat-content>
                </ul>
              </div>
              <div class="chat-message clearfix">
                <div class="input-group mb-0">
                  <div class="input-group-prepend">
                    <label class="input-group-text"><i class="fa fa-image"></i><input multiple
                        accept="image/jpeg,image/png,image/jpg" @change="uploadImage" class="FileUpload1" id="FileInput"
                        type="file" /></label>
                  </div>
                  <input type="text" v-on:keyup.enter="onEnter" class="form-control" v-model="textMessage"
                    placeholder="Type a message" tabindex="1">
                  <div class="input-group-prepend ms-2" v-if="this.textMessage != ''">
                    <span class="input-group-text" @click="sendUserMessage()" tabindex="2"><iconify-icon
                        icon="carbon:send-alt-filled" width="25" height="25"></iconify-icon></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal fade order-img" data-backdrop="static" data-keyboard="false" id="chatimgOpen" tabindex="-1"
      aria-labelledby="chatimgOpen" aria-hidden="true" ref="chatimgOpenModal" style="z-index: 9999;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content order-img-modal">
          <div class="modal-body text-center">
            <a data-bs-dismiss="modal" class="lnk crosclose">X</a>
            <img :src="imageUrl">
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Vue from 'vue'
import { mapActions, mapMutations } from "vuex";
import VSocket from 'vue-socket.io';
import io from "socket.io-client";
import NavBar from "@/components/NavBar.vue";
import Footer from '../components/Footer.vue';
import moment from "moment";
import 'moment-timezone';
import config from '../config';
import ChatContent from '../components/ChatContent.vue';

Vue.use(new VSocket({
  debug: true,
  connection: config.socket_url,
  vuex: {
    actionPrefix: "",
    mutationPrefix: "",
  }
}))
export default {
  name: "SupportChat",
  components: { NavBar, Footer, VueGoogleAutocomplete, ChatContent },
  data() {
    return {
      isLoading: false,
      roomID: "",
      innerHtml: '',
      textMessage: '',
      dafultMessages: [],
      defaltMsgDisplay: true,
      messages: [],
      temp: {
        old_unique_date: null
      },
      imageUrl: '',
      isStopCallingApi: false,
      skip: 0,
      tempArray: []
    };
  },

  sockets: {
    connect() {
      //console.log("connect!!!");
    },
  },

  watch: {
    roomID: function () {
      if (this.roomID !== '') {
        this.$socket.emit("room", this.roomID)
        this.sockets.subscribe('messagePass', (newmsg) => {
          var testDateUtc = moment.utc(newmsg.created_at);
          var localDate = moment(testDateUtc).local();
          var time = localDate.format("hh:mm A");
          let imgClass = (newmsg.image_url == '' ? 'noImg' : 'imgclass');
          newmsg.time = time;
          newmsg.imgClass = imgClass;
          newmsg.displydate = '';
          this.messages.push(newmsg)
          // if(newmsg.sender_id == this.$auth.user.user_id){
          // this.innerHtml += `<li class="clearfix">
          //     <div class="mesgs-right">
          //       <div class="message other-message float-right"><span class="cht-img `+imgClass+`" id="pop"><img v-on:click="showModal" src="`+newmsg.image_url+`" ></span>`+(newmsg.image_url == ''?newmsg.message:'')+`<span class="message-data-time">`+time+`</span></div>
          //     </div></li>`;
          // }else{
          //   this.innerHtml += `<li class="clearfix">
          //    <div class="message my-message"><span class="cht-img `+imgClass+`" id="pop"><img  src="`+newmsg.image_url+`"></span>`+(newmsg.image_url == ''?newmsg.message:'')+`<span class="message-data-time">`+time+`</span></div></div></li>`;
          // }
        });
      }
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.$auth.user && this.$auth.setting && this.$auth.setting.client_chat_status == 'active') {
        this.getUserMessage();
        this.customerDefaultMSG();
        setTimeout(() => {
          this.scrollToBottom();
        }, 600);
      } else {
        this.$router.go(-1);
      }
      $('#allMessagesId').bind("scroll", () => {
        if ($('#allMessagesId').scrollTop() == 0) {
          if (!this.isStopCallingApi) {
            this.getUserMessage();
          }
        }
      });
    }, 500);
  },

  updated() {
    this.scrollToBottom();
  },

  methods: {
    ...mapActions("user", ["getUserSupportChat", "sendSupportMessages", "customerDefaultMessageList"]),

    getUserMessage() {
      this.getUserSupportChat({
        is_langauge: this.$store.state.lang.locale,
        another_user_id: this.$auth.getVendorId(),
        login_user_id: this.$auth.user.user_id,
        skip: this.skip,
        total_record: 100
      }).then(data => {
        if (data.code == 200) {
          this.skip = this.skip + data.Result.length;
          if (data.Result.length <= 100) {
            this.isStopCallingApi = false;
            this.roomID = "R" + this.$auth.getVendorId() + "S" + this.$auth.user.user_id;
            this.roomID = this.roomID;
            let chats = [];
            let allData = [];
            if (this.tempArray.length == 0) {
              allData = data.Result.reverse();
            } else {
              allData = data.Result;
            }
            let newAllMsg = [];
            allData.forEach((msg) => {
              var testDateUtc = moment.utc(msg.time);
              var localDate = moment(msg.time).local();
              var time = localDate.format("hh:mm A");
              let imgClass = (msg.image_url == '' ? 'noImg' : 'imgclass');
              msg.displydate = this.groupByDate(msg.time);
              msg.time = time;
              msg.imgClass = imgClass;
              newAllMsg.push(msg);
            });
            if (this.tempArray.length == 0) {
              this.tempArray = this.tempArray.concat(newAllMsg);
              this.messages = this.tempArray;
            } else {
              this.tempArray = this.tempArray.reverse().concat(newAllMsg);
              this.messages = this.tempArray.reverse();
            }
          } else {
            this.isStopCallingApi = true;
          }
        } else {
          this.roomID = "R" + this.$auth.getVendorId() + "S" + this.$auth.user.user_id;
          this.isStopCallingApi = true;
        }
      });
    },

    senddefaultMessage(msg) {
      this.defaltMsgDisplay = false;
      this.textMessage = msg;
      this.sendUserMessage();
    },

    sendUserMessage() {
      if (this.textMessage) {
        this.sendSupportMessages({
          is_langauge: this.$store.state.lang.locale,
          another_user_id: this.$auth.getVendorId(),
          sender_id: this.$auth.user.user_id,
          receiver_id: this.$auth.getVendorId(),
          message: this.textMessage,
          type: "customer",
          imgage: '',
          random_id: Math.floor(100000000 + Math.random() * 900000000),
        }).then(data => {
          if (data.code == 200) {
            let newmsg = data.chat;
            var testDateUtc = moment.utc(data.chat.created_at);
            var localDate = moment(testDateUtc).local();
            var time = localDate.format("hh:mm A");
            let imgClass = (data.chat.image_url == '' ? 'noImg' : 'imgclass');
            newmsg.time = time;
            newmsg.imgClass = imgClass;
            newmsg.displydate = '';
            //this.sendCurrentMsg = newmsg;
            this.$socket.emit("messagePass", {
              room: this.roomID,
              message: this.textMessage,
              image_url: "",
              receiver_id: this.$auth.getVendorId(),
              sender_id: this.$auth.user.user_id,
              sender_name: this.$auth.user.user_name
            })
            this.textMessage = '';
          }
        });
      }
    },

    uploadImage(e) {
      var selectedFiles = e.target.files;
      var formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('image', selectedFiles[i]);
      }
      formData.append('is_langauge', this.$store.state.lang.locale);
      formData.append('another_user_id', this.$auth.getVendorId());
      formData.append('sender_id', this.$auth.user.user_id);
      formData.append('receiver_id', this.$auth.getVendorId());
      formData.append('message', this.textMessage);
      formData.append('type', "customer");
      this.sendSupportMessages(formData).then(data => {
        if (data.code == 200) {
          // this.innerHtml += `<li class="clearfix"><div class="mesgs-right">
          // <div class="message other-message float-right"><span class="cht-img `+imgClass+`"><img src="`+data.chat.image_url+`"></span>`+data.chat.message+`<span class="message-data-time">`+time+`</span></div></div></li>`;
          this.$socket.emit("messagePass", {
            room: this.roomID,
            message: this.textMessage,
            image_url: data.chat.image_url,
            receiver_id: this.$auth.getVendorId(),
            sender_id: this.$auth.user.user_id,
            sender_name: this.$auth.user.user_name,
          })
          this.textMessage = '';
        }
      });
    },

    scrollToBottom() {
      let messages = document.getElementById('allMessagesId');
      messages.scrollTop = messages.scrollHeight;
    },

    customerDefaultMSG() {
      // this.customerDefaultMessageList({
      //   is_langauge : this.$store.state.lang.locale,
      //   vendor_id :this.$auth.getVendorId(),          
      // }).then(data => {
      //   if (data.code == 200) {
      //     this.dafultMessages = data.Result.messages;
      //   }else{
      //     this.dafultMessages = [];
      //   }
      //});
    },

    groupByDate(date) {
      var old_date = this.temp.old_unique_date;
      var new_date = moment(date).format('YYYY-MM-DD');
      if (!moment(new_date).isSame(old_date)) {
        this.temp.old_unique_date = new_date;
        var date_data = moment(date).format('DD MMM YYYY');
        return { date: date_data, class: 'seedate' };
      } else {
        return { date: '', class: 'noseedate' };
      }
    },

    openPreviewImage(imageURL) {
      this.imageUrl = imageURL;
      $("#chatimgOpen").modal().show();
    },
    onEnter() {
      this.sendUserMessage();
    }
  },
};
</script>